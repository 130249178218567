import React from "react";
import SEO from "../components/seo";
import classnames from "classnames";
import SignUpButton from "../components/SignUpButton";

const plans = [
    {
        name: "Startup",
        price: 29,
        users: 7,
    },
    {
        name: "Small",
        price: 59,
        users: 15,
        popular: true,
    },
    {
        name: "Medium",
        price: 99,
        users: 25,
    },
    {
        name: "Large",
        price: 199,
        users: 50,
    },
];

const Plan = ({ plan, style = {} }) => {
    return (
        <div
            className={classnames("flex-1 relative px-4 md:px-0 pt-8 pb-8 ", {
                "md:-mt-8 md:-mb-6 md:-ml-6 md:-mr-2": plan.popular,
            })}
            style={{ ...style, maxWidth: 300 }}
        >
            <div
                className={"rounded-xl"}
                style={{
                    position: "absolute",
                    background: "white",
                    left: 0,
                    right: -4,
                    top: 40,
                    bottom: 0,
                }}
            />
            <div className={"rounded-xl overflow-hidden shadow-md h-full relative"} style={{ marginRight: -4 }}>
                <div
                    className={classnames("bg-gray-800 text-white px-8 py-4", {
                        "bg-blue-500": plan.popular,
                    })}
                >
                    {plan.popular && <div className={"font-extrabold"}>Most popular</div>}
                    <div className={"text-xl pt-2"}>{plan.name}</div>
                    <div>
                        <span className={"font-extrabold text-3xl"}>{plan.price}$</span>
                        <span className={"font-light text-base"}>/month</span>
                    </div>
                </div>
                <div className={"px-8 py-8 pb-16"}>
                    <div>
                        <span className={"font-extrabold text-2xl"}>{plan.users}</span> users
                    </div>
                    <hr className={"mr-40 my-4"} />
                    <div>
                        <span className={"font-extrabold"}>Unlimited</span> projects
                    </div>
                    <div>
                        <span className={"font-extrabold"}>Unlimited</span> teams
                    </div>
                    <hr className={"mr-40 my-4"} />
                    <div className={"font-extrabold"}>Slack</div>
                    <div className={"font-extrabold"}>Dropbox</div>
                    <div className={"font-extrabold"}>Google Drive</div>
                    <div>
                        <span className={"font-extrabold"}>OneDrive</span> integration
                    </div>
                </div>
            </div>
        </div>
    );
};

const FaqBlock = ({ title, content }) => {
    return (
        <div className={"mb-16 py-8"}>
            <h4 className={"text-xl font-extrabold mb-8"}>{title}</h4>
            <p className={"leading-relaxed"}>{content}</p>
        </div>
    );
};

const SecondPage = ({location}) => (
    <div className={"pb-32"}>
        <SEO
            location={location}
            breadcrumb={"Pricing"}
            title="Plans & Pricing"
            description={"An affordable agile tool for startups, small and medium organizations with a 15-day free trial."}
        />
        <section className="relative pt-32 mb-64 px-4" style={{ minHeight: "100vh" }}>
            <div className={"container mx-auto text-center"}>
                <h1 className={"text-2xl xsm:text-5xl font-extrabold mb-8"}>All plans come with a 15-day free trial</h1>
                <p>Plans are billed monthly. No tricks. No obligations.</p>
            </div>
            <div className={"container mt-32 mx-auto p-4"}>
                <div className={"flex flex-row flex-wrap mx-auto justify-center"} style={{ paddingRight: 4 }}>
                    {plans.map((plan, i) => (
                        <Plan plan={plan} key={plan.name} style={{ zIndex: plan.popular ? 20 : plans.length - i }} />
                    ))}
                </div>
            </div>
            <div className={"text-center mt-8"}>
                <p>
                    Are you looking to onboard more than 50 people? Contact us at <span className={"font-extrabold"}>help@scrummate.com</span>.
                </p>
                <h2 className={"text-2xl font-extrabold mb-8 mt-16"}>Start your free 15-day trial</h2>
                <SignUpButton className={""} />
            </div>
        </section>
        <section className={"faqWrapper px-4"}>
            <h3 className={"text-2xl md:text-4xl font-extrabold mb-16"}>Your investment is safe</h3>
            <FaqBlock
                title={"Is it reliable?"}
                content={
                    <span>
                        Yes, Scrum Mate is absolutely reliable. Scrum Mate is hosted in Microsoft Azure Cloud, primarily in Ireland and
                        managed 7/24 a week. Your data are stored at two different locations, on three different disks each location. You
                        can learn more at{" "}
                        <a href={"https://azure.microsoft.com/en-us/regions/"} className={"text-blue-500 font-extrabold"}>
                            Azure site.
                        </a>
                    </span>
                }
            />
            <FaqBlock
                title={"Can I cancel at any time?"}
                content={
                    "Yes, you can cancel your subscription at any time. Once you canceled the subscription no further charges will be applied. If you cancel your subscription before its expiration date, your projects will remain fully functioning until the expiration date."
                }
            />
            <FaqBlock
                title={"How can I change the plan?"}
                content={
                    'You can change your plan whenever you wish. You can do it within Scrum Mate application  at "My subscription" settings. All your data will be available after changing the plan. Please note, that there are no automatic refund if you are downgrading during a prepaid period.'
                }
            />
            <FaqBlock
                title={"Can I get a refund?"}
                content="Your satisfaction is our primary goal. If you are unhappy with our service, contact us at help@scrummate.com and get a refund of your last monthly subscription fee."
            />
            <FaqBlock
                title={"How will I get my invoice?"}
                content="Upon a successful payment for your Scrum Mate account, you will receive your invoice within 2 working days via email with the details of the transaction."
            />
            <FaqBlock
                title={"What browsers can I use with the system?"}
                content="Scrum Mate provides the best user experience on Google Chrome and Mozilla Firefox. The system has also been tested and can be used with Microsoft Edge and Apple safari."
            />
        </section>
    </div>
);

export default SecondPage;
